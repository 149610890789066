import React from 'react';
import styled, { css } from 'styled-components';
import backgroundImage from '../../assets/images/paired-ngs/header_cover.webp';
import ScrollLink from '../../components/atoms/ScrollLink';
import {
  TextLarge,
  TextMedium,
  Title,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';

const IntroSection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);

  return (
    <Wrapper mobileLayout={mobileLayout}>
      <MainContentColumnWrapper>
        <IntroSectionWrapper>
          <TextsBlock>
            <SectionTitle>
              PairedNGS <br /> Database
            </SectionTitle>
            <SectionText>
              <SectionPunchline>
                Large database of paired heavy and light chains for machine
                learning and data mining applications.
              </SectionPunchline>
            </SectionText>
            <ScrollLink $color="delicate" to="#introduction">
              <ScrollIcon />
              Learn more
            </ScrollLink>
          </TextsBlock>
        </IntroSectionWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobileLayout: boolean }>`
  ${({ mobileLayout }) =>
    !mobileLayout &&
    css`
      height: 1000px;
    `}
  background-image: url('${backgroundImage}');
  background-size: cover;
  background-blend-mode: overlay;
  background-position: top center;
  position: relative;
  height: 800px;

  :before {
    content: '';
    position: absolute;
    width: 80%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  ${forScreenAtMost680pxWide(css`
    :before {
      width: 50%;
    }
  `)}
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 455px;
  margin: 7px 0 25px;
  font-size: 70px;
  line-height: 70px;
`;

const SectionPunchline = styled(TextLarge).attrs({
  as: 'span',
  $color: 'primaryAccented',
})`
  display: block;
  max-width: 455px;
  margin: 7px 0 25px;
  color: #a7b9bb;
`;

const SectionText = styled(TextMedium).attrs({ $color: 'delicate' })`
  max-width: 424px;
  margin: 0 0 32px;
`;

const TextsBlock = styled.div`
  padding: 50px 0 20px 0;
`;

const IntroSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-top: 187px;

  ${forScreenAtMost680pxWide(css`
    grid-template-columns: 1fr;
  `)}
`;

export default IntroSection;
