import React from 'react';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import styled from 'styled-components';
import {
  GradientTitle,
  TextLarge,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import BioprojectsIcon from '../../assets/images/bioprojects.inline.svg';
import SequencesAmountIcon from '../../assets/images/sequencesAmount.inline.svg';
import OrganismsAmountIcon from '../../assets/images/paired-ngs/sequencesamount.inline.svg';
import CheckIcon from '../../assets/images/check.inline.svg';
import { RegularLink } from '../../components/atoms/Link';
import {
  forScreenAtLeast1000pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import { css } from 'styled-components';
import NavButton from '../../components/atoms/NavButton';
import routes from '../../routes';

export const descriptionSectionId = 'ngs-description-section';

const MainSection = (): JSX.Element => {
  return (
    <Wrapper id={descriptionSectionId}>
      <InnerWrapper>
        <MainContentColumnWrapper>
          <TextWrapper>
            <TitleText
              id="introduction"
              $color="secondary"
              $textAlign="center"
              $fontWeight="700"
            >
              Introduction
            </TitleText>
            <BaseText $color="delicateAccented">
              Next generation sequencing is producing copious amounts of
              sequence data that deepen our understanding of biological
              processes and allow for development of ever more efficient
              modeling techniques. Antibodies are composed of heavy and light
              chains and first NGS technology addressing these molecules
              produced exclusively unpaired entries. The largest repositories to
              exist of antibody sequences have billions of entries but are
              unpaired (see
              <Link bold to={routes.ngs}>
                {' '}
                naturalantibody.com/ngs
              </Link>
              ).
            </BaseText>
            <BaseText $color="delicateAccented">
              To address this discrepancy we developed the PairedNGS dataset - a
              large repository of natural paired antibodies from a heterogeneous
              set of studies.
            </BaseText>
          </TextWrapper>
          <StatisticsWrapper>
            <Upheader $color="delicateAccented">Database statistics</Upheader>
            <StatisticsContentWrapper>
              <StatisticWrapper>
                <BioprojectsIcon />
                <StatisticTextWrapper>
                  <StatisticText $color="secondary" $fontWeight="700">
                    56
                  </StatisticText>
                  <BaseText $color="delicateAccented">bioprojects</BaseText>
                </StatisticTextWrapper>
              </StatisticWrapper>
              <StatisticWrapper>
                <OrganismsAmountIcon />
                <StatisticTextWrapper>
                  <StatisticText $color="secondary" $fontWeight="700">
                    2
                  </StatisticText>
                  <BaseText $color="delicateAccented">
                    organisms <br />
                    (human, mouse)
                  </BaseText>
                </StatisticTextWrapper>
              </StatisticWrapper>
              <StatisticWrapper>
                <SequencesAmountIcon />
                <StatisticTextWrapper>
                  <StatisticText $color="secondary" $fontWeight="700">
                    ~7M
                  </StatisticText>
                  <BaseText $color="delicateAccented">
                    paired sequences
                  </BaseText>
                </StatisticTextWrapper>
              </StatisticWrapper>
            </StatisticsContentWrapper>
          </StatisticsWrapper>
          <TextWrapper>
            <BaseText $color="delicateAccented">
              Our dataset currently covers
              <BaseText $color="secondary" $fontWeight="700" as="span">
                {' '}
                56 bioprojects
              </BaseText>
              . We divided that dataset into the
              <BaseText $color="secondary" $fontWeight="700" as="span">
                {' '}
                fasta version{' '}
              </BaseText>
              and the
              <BaseText $color="secondary" $fontWeight="700" as="span">
                {' '}
                .airr version
              </BaseText>
              . The .fasta version is designed to be more lightweight and to
              give users an immediate entry point to using our data. The .airr
              dataset is designed to fulfill the community standard
              requirements.
            </BaseText>
          </TextWrapper>
          <TextWrapper>
            <TitleText $color="secondary" $fontWeight="700" $textAlign="left">
              .fasta dataset
            </TitleText>
            <BaseText $color="delicateAccented">
              There is a .fasta file for each independent study, with the name
              giving the organism and the bioproject ID. For instance
              human_PRJNA1024473.fasta contains human sequences from
              PRJNA1024473. Each entry in the fasta file has a header with the
              following metadata:
            </BaseText>
            <List>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  Bioproject ID
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  Heavy gene calls
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  SRA ID
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  Kappa gene calls
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  Organism
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  CDR-H3 sequence
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  Barcode
                </TextMedium>
              </ListItem>
              <ListItem>
                <CheckIcon />
                <TextMedium $color="delicateAccented" $fontWeight="700">
                  CDR-L3 sequence
                </TextMedium>
              </ListItem>
            </List>
            <TextMedium $color="delicateAccented">
              The sequence record is given as a heavy chain amino acid sequence
              first followed by a separator sign &#8217;/&#8217; and then light
              chain.
            </TextMedium>
            <TextMedium $color="delicateAccented">
              An example of a sequence entry formatted in this fashion is given
              below:
            </TextMedium>
            <Formula>
              <BaseText $color="secondary" $fontWeight="700">
                &gt;PRJNA1024473|SRR26292911|human|ATGCTACGTAAGTAGT|IGHV1-69*06,IGHD1-14*01,IGHJ5*02,IGHA1|IGKV1D-39*01,IGKJ2*01,IGKC|ASKGSLVSHYFDP|QQSYSTPTYT
                QVQLVQSGAEVKKPGSSVKVSCKASGDTFSSYGITWVRQAPGQGLEWMGGIIPMFGTTNYARKFQGRVTITADKSTSTAYMELSSLRSDDTAVYYCASKGSLVSHYFDPWGQGTLVTVSS/DIQMTQSPSSLSASVGDRVTITCRASQNIISYLNWYQQKPGKAPKLLIHAASTLQSGVPSRFSGSGSGTDFTLTISSLQPEDFATYYCQQSYSTPTYTFGQGTKLEIK
              </BaseText>
            </Formula>
          </TextWrapper>
          <TextWrapper>
            <TitleText $color="secondary" $fontWeight="700" $textAlign="left">
              .airr dataset
            </TitleText>
            <BaseText $color="delicateAccented">
              The .airr dataset adheres to the standards given by the community
              as defined{' '}
              <Link
                underline
                to="https://docs.airr-community.org/en/stable/datarep/rearrangements.html#fields"
              >
                here
              </Link>
              . The paired dataset contains additional fields as described in
              the Table 1 below.
            </BaseText>
            <BaseText $color="delicateAccented">
              Table 1. PairedNGS database in the .airr format, together with
              extra fields.
            </BaseText>
            <Table>
              <thead>
                <tr>
                  <th>
                    <TextLarge $color="secondary" $fontWeight="700">
                      Name
                    </TextLarge>
                  </th>
                  <th>
                    <TextLarge $color="secondary" $fontWeight="700">
                      Type
                    </TextLarge>
                  </th>
                  <th>
                    <TextLarge $color="secondary" $fontWeight="700">
                      Definition
                    </TextLarge>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <TextLarge $color="secondary">sra</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      NCBI identifier of the read run
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">scheme</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      The name of the numbering scheme applied.
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">v_frame</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">int</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      V gene reading frame offset from v_alignment_start. Could
                      be [0,1,2].
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">j_frame</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">int</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      J gene reading frame offset from j_alignment_start. Could
                      be [0,1,2].
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">
                      sequence_aa_scheme_cigar
                    </TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      Alignment of the amino acid sequence to the scheme of
                      choice specified in CIGAR format.
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">
                      scheme_residue_mapping
                    </TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      Base64 encoded json object containing scheme to residue
                      mapping.
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">
                      positional_scheme_mapping
                    </TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      Base64 encoded json object containing amino acid sequence
                      positional to scheme mapping.
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">
                      additional_validation_flags
                    </TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      Base64 encoded json object containing results from
                      validations performed by RIOT.
                    </TextLarge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextLarge $color="secondary">exc</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">string</TextLarge>
                  </td>
                  <td>
                    <TextLarge $color="secondary">
                      Exception (if any) thrown during numbering.
                    </TextLarge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextWrapper>
          <Block>
            <BlockHeader>Data availability & access</BlockHeader>
            <BaseText $color="primary" $textAlign="center" $fontWeight="700">
              We make the dataset available to non-profit organizations for
              non-commercial purposes. Other organizations & usage types, are
              kindly asked to{' '}
              <GreenLink to={routes.contactUs}>contact us</GreenLink>
            </BaseText>
            <ButtonsWrapper>
              <NavButton to="https://drive.google.com/file/d/13d26pSjMEvLGVJ3nXfLvrzOK5cFXfMJW/view?usp=sharing">
                .fasta version dataset
              </NavButton>
              <NavButton to="https://drive.google.com/file/d/1VfKSVrFnUOkTCWYM8Z_LHWlrMZmRyOmk/view?usp=sharing">
                .airr version dataset
              </NavButton>
            </ButtonsWrapper>
          </Block>
          <TextWrapper>
            <TitleText $color="secondary" $fontWeight="700" $textAlign="center">
              Citing this work
            </TitleText>
            <BaseText $color="delicateAccented">
              We make the PairedNGS database available as a companion to our
              paper, that will be posted here once it clears reviews.
            </BaseText>
            <SummaryWrapper>
              <SummaryText $color="secondary" $fontWeight="700">
                PairedNGS - Large dataset of paired heavy and light chains for
                machine learning and data mining applications.
              </SummaryText>
              <SummaryLabel>
                <SummaryLabelText
                  $color="tertiary"
                  $fontWeight="700"
                  $uppercase
                >
                  Under review
                </SummaryLabelText>
              </SummaryLabel>
            </SummaryWrapper>
          </TextWrapper>
        </MainContentColumnWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

const Link = styled(RegularLink)<{ bold?: boolean; underline?: boolean }>`
  font-size: 16px;
  font-weight: 700;
  color: #000;
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}
`;

const GreenLink = styled(RegularLink)`
  color: #73e2a7;
  font-size: 18px;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  ${forScreenAtMost680pxWide(css`
    flex-direction: column;
  `)};

  a {
    background-color: #73e2a7;
    color: #0a2631;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1.5rem;
  background-color: #103f46;
  margin-bottom: 4rem;
  border-radius: 25px;
  box-shadow: 2px 8px 48px -11px rgba(66, 68, 90, 1);

  p {
    width: 552px;
    font-size: 18px;
    ${forScreenAtMost680pxWide(css`
      width: 100%;
    `)};
  }
`;

const BlockHeader = styled(GradientTitle).attrs({
  as: 'h2',
  $gradient: 'secondary',
})`
  margin-bottom: 1rem;

  ${forScreenAtMost680pxWide(css`
    text-align: center;
  `)};
`;

const Table = styled.table`
  border-collapse: collapse;

  ${forScreenAtMost680pxWide(css`
    width: 100%;
    max-width: 300px;
  `)};

  td,
  th {
    border: 1px solid #999;
    padding: 1rem;
    text-align: left;
    width: 33%;

    ${forScreenAtMost680pxWide(css`
      max-width: 135px;
    `)};

    p {
      word-wrap: break-word;
      max-width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0 0 400px 0;
  background: ${(p) => p.theme.colors.backgrounds.secondary};
  ${forScreenAtLeast1000pxWide(css`
    padding: 0 0 320px 0;
  `)};
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    width: 100%;
    max-width: 1134px;
    height: 10px;
    transform: translateX(-50%);
    background: ${(p) => p.theme.colors.backgrounds.secondary};
  }
`;

const InnerWrapper = styled.div<{ useGreyBg?: boolean }>`
  padding: 60px 0;
  background: ${(p) =>
    p.useGreyBg
      ? p.theme.colors.backgrounds.quaternary
      : p.theme.colors.backgrounds.secondary};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  &:not(:last-child) {
    margin: 0 0 60px 0;
  }
  ${forScreenAtLeast1000pxWide(css`
    padding: 0 180px;
  `)}
`;

const TitleText = styled(TextMedium)`
  font-size: 28px;
`;

const BaseText = styled(TextMedium)`
  font-size: 16px;
`;

const StatisticsWrapper = styled.div`
  margin: 0 -30px 60px -30px;
  padding: 40px 30px 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  background: ${(p) => p.theme.colors.backgrounds.tertiary};
  ${forScreenAtLeast1000pxWide(css`
    margin: 0 0 60px 0;
    padding: 40px 20px 60px 20px;
  `)}
`;

const StatisticsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  ${forScreenAtLeast1000pxWide(css`
    flex-direction: row;
    column-gap: 80px;
    row-gap: 0;
  `)}
`;

const StatisticWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const StatisticTextWrapper = styled.div``;

const StatisticText = styled(TextMedium)`
  font-size: 48px;
  line-height: 0.9;
`;

const List = styled.ul<{ useOneColumn?: boolean }>`
  margin: 0;
  padding: 0;
  display: grid;
  align-items: flex-start;
  column-gap: 80px;
  row-gap: 20px;
  ${forScreenAtLeast1000pxWide(css<{ useOneColumn?: boolean }>`
    grid-template-columns: repeat(${(p) => (p.useOneColumn ? 1 : 2)}, 1fr);
  `)}
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: 20px;

  svg {
    flex-shrink: 0;
  }
`;

const Formula = styled.div`
  padding: 4px 10px;
  background: ${(p) => p.theme.colors.backgrounds.quaternary};

  ${BaseText} {
    word-break: break-word;
  }
`;

const SummaryWrapper = styled.div`
  padding: 10px 0 10px 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  border-left: 5px solid ${(p) => p.theme.colors.backgrounds.tertiary};
`;

const SummaryText = styled(TextMedium)`
  font-size: 20px;
`;

const SummaryLabel = styled.div`
  margin: 15px 0 0 0;
  padding: 0 15px;
  border-radius: 50px;
  background: #efe4bb;
`;

const SummaryLabelText = styled(TextMedium)`
  font-size: 10px;
  letter-spacing: 0.1em;
`;

export default MainSection;
