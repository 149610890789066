import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import IntroSection from '../pages-components/paired-ngs/IntroSection';
import MainSection from '../pages-components/paired-ngs/MainSection';

const PairedNGSPage = (): JSX.Element => {
  return (
    <Layout
      description="Accelerate your research with a curated and comprehensive knowledge graph that combines decades of research into antibody engineering."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="PairedNGS Database"
    >
      <IntroSection />
      <MainSection />
    </Layout>
  );
};

export default PairedNGSPage;
